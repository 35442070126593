import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Credentials, CredentialsService } from "./credentials.service";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";

export interface LoginContext {
  userName: string;
  password: string;
  remember?: boolean;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  constructor(
    private credentialsService: CredentialsService,
    private http: HttpClient,
    private router: Router
  ) {}

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(loginDetails: any, hashedPassword: string): Observable<Credentials> {
    let data = { email: loginDetails.email, password: hashedPassword };
    return this.http.post<any>("/admin-providers/login", data).pipe(
      map((res) => {
        const userData = {
          username: loginDetails.email,
          token: res.data.accessToken,
          userDetails: res.data,
          userType: res.data.providerType,
        };
        this.credentialsService.setCredentials(userData, loginDetails.remember);

        return res;
      })
    );
  }

  forgotPassword(data: string): Observable<Credentials> {
    return this.http.patch<any>("/admin-providers/forgot-password", data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  resetPassword(password: string, oldPassword?: string) {
    let data = { password: password, oldPassword: oldPassword };
    return this.http.patch<any>("/admin-providers/change-password", data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  codeVerification(code: any) {
    return this.http.post<any>(`/admin-providers/verify/${code}`, {}).pipe(
      map((res) => {
        const userData = {
          username: res.data.email,
          token: res.data.accessToken,
          userDetails: res.data,
          userType: res.data.providerType,
        };
        this.credentialsService.setCredentials(userData, false);
        return res;
      })
    );
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    return this.http.post<any>("/admin-providers/logout", {}).pipe(
      map((res) => {
        this.credentialsService.setCredentials();
        return true;
      })
    );
  }
}
