import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthenticationService } from "../core/authentication.service";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { OrganizationService } from "../home/organization/organization.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  forgotpasswordForm: FormGroup;
  showSuccessMsg: boolean = false;
  showErrorMsg: boolean = false;
  errorMsg: any;
  loading: boolean = false;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private orgService: OrganizationService,
    private authService: AuthenticationService,
    private translationService: TranslateService
  ) {}

  ngOnInit() {
    let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    this.forgotpasswordForm = this.fb.group({
      email: ["", [Validators.required, Validators.pattern(emailregex)]],
    });
  }

  forgotPasswordSubmit() {
    if (this.forgotpasswordForm.invalid) {
      return;
    }
    this.loading = true;
    this.showErrorMsg = false;
    this.showSuccessMsg = false;
    this.authService.forgotPassword(this.forgotpasswordForm.value).subscribe(
      (res: any) => {
        this.loading = false;
        // this.showSuccessMsg = true;
        // this.orgService.showMessage(
        //   `OTP has been send to registered email`,"success"
        // );
        this.translationService
          .get("The reset password link has been sent to your registered mail.")
          .subscribe((text: string) => {
            this.orgService.showMessage(text, "success");
          });
      },
      (error: HttpErrorResponse) => {
        this.showErrorMsg = true;
        this.loading = false;
        this.errorMsg = error.error.errors;
      }
    );
  }
}
