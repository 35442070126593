<app-home-header></app-home-header>

<div class="container" style="margin-top: 15px;">
  <div class="row">
    <div class="col-9" style="padding-left: 20px;">
      <h1 class="title-dashboard">
        Dashboard
      </h1>
      <p class="sub-title-dashboard" style="margin-top: -15px;">
        inicio / <span class="sub-title-dashboard-2">Dashboard</span>
      </p>
    </div>
    <div class="col-3 d-flex justify-content-end">
      <button
        [disabled]="isLoading"
        mat-button
        [matMenuTriggerFor]="menu"
        style="
          background-color: #244791;
          height: 48px;
          width: 190px;
          color: white;
          font-family: 'Nunito', sans-serif;
          min-width: 190px;
        "
      >
        <mat-icon>date_range</mat-icon> {{ rangeSelected.label }}
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button
          *ngFor="let range of dateRanges"
          mat-menu-item
          style="
            width: 207px;
            font-size: 15px;
            font-family: 'Nunito', sans-serif;
          "
          (click)="changeRange(range.value)"
        >
          {{ range.label }}
        </button>
      </mat-menu>
    </div>
  </div>

  <div class="row">
    <div class="col-6 card-responsive">
      <mat-card class="card-container">
        <div class="row">
          <div class="col-10">
            <mat-card-title
              style="
                font-size: 20px;
                font-family: 'Nunito', sans-serif;
                font-weight: bold;
              "
            >
              Usuarios registrados
              <mat-icon
                style="position: absolute; margin-left: 5px; color: #244791;"
                matTooltip="Personas que han instalado la app de HolaDOC y completado el proceso de registro en ella, al introducir y validar sus datos personales y creando sus credenciales de acceso."
                matTooltipClass="custom-tooltips"
                matTooltipPosition="after"
                >info_outline</mat-icon
              >
            </mat-card-title>
            <mat-card-title *ngIf="!isLoading" class="card-subtitle"
              >{{ isData ? totalRegistered : "0" }}
              <span
                *ngIf="members?.trend > 0 && isData"
                style="
                  font-size: 14px;
                  position: absolute;
                  top: 40px;
                  margin-left: 10px;
                  color: #23bc60;
                "
              >
                <img
                  style="width: 10px;"
                  src="../../assets/images/flecha-verde.png"
                  alt=""
                />
                {{ isInteger(members.trend) }}%
              </span>
              <span
                *ngIf="members?.trend < 0 && isData"
                style="
                  font-size: 14px;
                  position: absolute;
                  top: 40px;
                  margin-left: 10px;
                  color: #d22f37;
                "
              >
                <img
                  style="width: 10px;"
                  src="../../assets/images/flecha-roja.png"
                  alt=""
                />
                {{ isInteger(members.trend) }}%
              </span>
              <span
                *ngIf="members?.trend == 0 && isData"
                style="
                  font-size: 14px;
                  position: absolute;
                  top: 40px;
                  margin-left: 10px;
                  color: rgba(0, 0, 0, 0.4);
                "
              >
                -- sin var.
              </span>
            </mat-card-title>

            <mat-card-title *ngIf="isLoading" class="card-process-bar">
              <mat-progress-bar color="warn" mode="indeterminate">
              </mat-progress-bar
            ></mat-card-title>

            <mat-card-subtitle *ngIf="!isLoading" class="card-info"
              >Total miembros: {{ totalMembers }}</mat-card-subtitle
            >
            <mat-card-subtitle *ngIf="isLoading" class="card-process-bar-info">
              <mat-progress-bar
                *ngIf="isLoading"
                color="warn"
                mode="indeterminate"
              ></mat-progress-bar>
            </mat-card-subtitle>
          </div>
          <div class="col-2">
            <button
              disabled
              mat-fab
              style="
                color: #244791;
                background-color: #d8e4fc;
                width: 64px;
                height: 64px;
              "
            >
              <img
                style="width: 95%; margin-top: -10%;"
                src="../../assets/images/Icon.svg"
                alt=""
              />
            </button>
          </div>
        </div>
        <mat-divider style="margin-top: 15px;"></mat-divider>

        <mat-card-content>
          <mat-spinner
            *ngIf="isLoading"
            style="margin-top: 20%; margin-left: 45%;"
            diameter="60"
          ></mat-spinner>

          <div *ngIf="!isLoading && members?.dates?.length <= 0">
            <p
              style="
                position: absolute;
                margin-top: 23%;
                margin-left: 30%;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              El gráfico aún no se encuentra disponible
            </p>
            <img
              style="width: 100%; height: 100%; margin-top: 20px;"
              src="assets/images/no-data-graphic.png"
            />
          </div>

          <div id="canvas2" class="line-graphic"></div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-6 card-responsive">
      <mat-card class="card-container">
        <div class="row">
          <div class="col-10">
            <mat-card-title
              style="
                font-size: 20px;
                font-family: 'Nunito', sans-serif;
                font-weight: bold;
              "
              >Consultas atendidas
              <mat-icon
                style="position: absolute; margin-left: 5px; color: #244791;"
                matTooltip="Número de consultas atendidas por el equipo médico HolaDOC en el período de tiempo seleccionado."
                matTooltipClass="custom-tooltips"
                matTooltipPosition="after"
                >info_outline</mat-icon
              ></mat-card-title
            >
            <mat-card-title *ngIf="!isLoading" class="card-subtitle"
              >{{ isData ? totalConsults : "0" }}
              <span
                *ngIf="dashboardData?.trendConsultation > 0 && isData"
                style="
                  font-size: 14px;
                  position: absolute;
                  top: 40px;
                  margin-left: 10px;
                  color: #23bc60;
                "
              >
                <img
                  style="width: 10px;"
                  src="../../assets/images/flecha-verde.png"
                  alt=""
                />
                {{ isInteger(dashboardData.trendConsultation) }}%
              </span>
              <span
                *ngIf="dashboardData?.trendConsultation < 0 && isData"
                style="
                  font-size: 14px;
                  position: absolute;
                  top: 40px;
                  margin-left: 10px;
                  color: #d22f37;
                "
              >
                <img
                  style="width: 10px;"
                  src="../../assets/images/flecha-roja.png"
                  alt=""
                />
                {{ isInteger(dashboardData.trendConsultation) }}%
              </span>
              <span
                *ngIf="dashboardData?.trendConsultation == 0 && isData"
                style="
                  font-size: 14px;
                  position: absolute;
                  top: 40px;
                  margin-left: 10px;
                  color: rgba(0, 0, 0, 0.4);
                "
              >
                -- sin var.
              </span>
            </mat-card-title>
            <mat-card-title *ngIf="isLoading" class="card-process-bar">
              <mat-progress-bar color="warn" mode="indeterminate">
              </mat-progress-bar
            ></mat-card-title>
            <mat-card-subtitle *ngIf="!isLoading" class="card-info"
              >Primarias: {{ isData ? primaries : "0" }} - Sucesivas:
              {{ isData ? successive : "0" }}</mat-card-subtitle
            >
            <mat-card-subtitle *ngIf="isLoading" class="card-process-bar-info">
              <mat-progress-bar
                *ngIf="isLoading"
                color="warn"
                mode="indeterminate"
              ></mat-progress-bar>
            </mat-card-subtitle>
          </div>
          <div class="col-2">
            <button
              disabled
              mat-fab
              style="
                color: #244791;
                background-color: #d8e4fc;
                width: 64px;
                height: 64px;
              "
            >
              <img
                style="width: 90%; margin-top: -10%;"
                src="../../assets/images/maleta.svg"
                alt=""
              />
            </button>
          </div>
        </div>

        <mat-divider style="margin-top: 15px;"></mat-divider>
        <mat-card-content>
          <mat-spinner
            *ngIf="isLoading"
            style="margin-top: 20%; margin-left: 45%;"
            diameter="60"
          ></mat-spinner>
          <div *ngIf="!isLoading && dashboardData?.dates?.length <= 0">
            <p
              style="
                position: absolute;
                margin-top: 23%;
                margin-left: 30%;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              El gráfico aún no se encuentra disponible
            </p>
            <img
              style="width: 100%; height: 100%; margin-top: 20px;"
              src="assets/images/no-data-graphic.png"
            />
          </div>

          <div id="canvas3" class="line-graphic"></div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="row" style="margin-top: 15px;">
    <div class="col-4 card-min-container-responsive">
      <mat-card class="card-min-container">
        <div class="row">
          <div class="col-9 card-min-responsive">
            <mat-card-title class="card-min-title"
              >Tasa de registo acumulada
              <mat-icon
                style="position: absolute; margin-left: 5px; color: #244791;"
                matTooltip="Porcentaje acumulado del total de usuarios registrados en la app de HolaDOC, en relación a los miembros de su organización"
                matTooltipClass="custom-tooltips"
                matTooltipPosition="after"
                >info_outline</mat-icon
              ></mat-card-title
            >
            <mat-card-title *ngIf="!isLoading" class="card-min-subtitle"
              >{{
                members?.accumulatedRegistered > 0
                  ? isInteger(members.accumulatedRegistered) + "%"
                  : "0"
              }}
            </mat-card-title>
            <mat-card-title *ngIf="isLoading" class="card-min-process-bar">
              <mat-progress-bar color="warn" mode="indeterminate">
              </mat-progress-bar
            ></mat-card-title>
          </div>
          <div class="col-2">
            <button
              disabled
              mat-fab
              style="
                color: #244791;
                background-color: #d8e4fc;
                width: 64px;
                height: 64px;
              "
            >
              <mat-icon style="transform: scale(1.2); font-weight: bold;"
                >data_usage</mat-icon
              >
            </button>
          </div>
        </div>
      </mat-card>

      <mat-card class="card-min-container">
        <div class="row">
          <div class="col-9 card-min-responsive">
            <mat-card-title class="card-min-title"
              >Tasa de uso
              <mat-icon
                style="position: absolute; margin-left: 5px; color: #244791;"
                matTooltip="Porcentaje de uso del servicio de telemedicina de HolaDOC en relación a los miembros de su organización considerando el período de tiempo seleccionado."
                matTooltipClass="custom-tooltips"
                matTooltipPosition="after"
                >info_outline</mat-icon
              ></mat-card-title
            >
            <mat-card-title *ngIf="!isLoading" class="card-min-subtitle"
              >{{
                dashboardData?.rateConsultation > 0
                  ? isInteger(dashboardData.rateConsultation) + "%"
                  : "0"
              }}
              <span
                *ngIf="dashboardData?.trendConsultation > 0 && isData"
                style="
                  font-size: 14px;
                  position: absolute;
                  top: 50px;
                  margin-left: 10px;
                  color: #23bc60;
                "
              >
                <img
                  style="width: 10px;"
                  src="../../assets/images/flecha-verde.png"
                  alt=""
                />
                {{ isInteger(dashboardData.trendConsultation) }}%
              </span>
              <span
                *ngIf="dashboardData?.trendConsultation < 0 && isData"
                style="
                  font-size: 14px;
                  position: absolute;
                  top: 50px;
                  margin-left: 10px;
                  color: #d22f37;
                "
              >
                <img
                  style="width: 10px;"
                  src="../../assets/images/flecha-roja.png"
                  alt=""
                />
                {{ isInteger(dashboardData.trendConsultation) }}%
              </span>
              <span
                *ngIf="dashboardData?.trendConsultation == 0 && isData"
                style="
                  font-size: 14px;
                  position: absolute;
                  top: 50px;
                  margin-left: 10px;
                  color: rgba(0, 0, 0, 0.4);
                "
              >
                -- sin var.
              </span>
            </mat-card-title>
            <mat-card-title *ngIf="isLoading" class="card-min-process-bar">
              <mat-progress-bar color="warn" mode="indeterminate">
              </mat-progress-bar
            ></mat-card-title>
          </div>
          <div class="col-2">
            <button
              disabled
              style="
                color: #244791;
                background-color: #d8e4fc;
                width: 64px;
                height: 64px;
              "
              mat-fab
            >
              <img
                style="width: 95%; margin-top: -10%;"
                src="../../assets/images/coolicon.svg"
                alt=""
              />
            </button>
          </div>
        </div>
      </mat-card>

      <mat-card class="card-min-container" style="margin-bottom: 0px;">
        <div class="row">
          <div class="col-9 card-min-responsive">
            <mat-card-title class="card-min-title"
              >Valoración media
              <mat-icon
                style="position: absolute; margin-left: 5px; color: #244791;"
                matTooltip="Valoración promedio que ha recibido la app y el servicio de telemedicina de HolaDOC, por parte de los usuarios de su organización considerando el período de tiempo seleccionado."
                matTooltipClass="custom-tooltips"
                matTooltipPosition="after"
                >info_outline</mat-icon
              ></mat-card-title
            >
            <mat-card-title
              *ngIf="!isLoading && dashboardData?.rateRating > 0"
              class="card-min-subtitle"
              >{{ isInteger(dashboardData.rateRating) + "/5" }}
              <span
                *ngIf="dashboardData.trendRating > 0 && isData"
                style="
                  font-size: 14px;
                  position: absolute;
                  top: 50px;
                  margin-left: 10px;
                  color: #23bc60;
                "
              >
                <img
                  style="width: 10px;"
                  src="../../assets/images/flecha-verde.png"
                  alt=""
                />
                {{ isInteger(dashboardData.trendRating) }}%
              </span>
              <span
                *ngIf="dashboardData.trendRating < 0 && isData"
                style="
                  font-size: 14px;
                  position: absolute;
                  top: 50px;
                  margin-left: 10px;
                  color: #d22f37;
                "
              >
                <img
                  style="width: 10px;"
                  src="../../assets/images/flecha-roja.png"
                  alt=""
                />
                {{ isInteger(dashboardData.trendRating) }}%
              </span>
              <span
                *ngIf="dashboardData.trendRating == 0 && isData"
                style="
                  font-size: 14px;
                  position: absolute;
                  top: 50px;
                  margin-left: 10px;
                  color: rgba(0, 0, 0, 0.4);
                "
              >
                -- sin var.
              </span>
            </mat-card-title>

            <mat-card-title
              *ngIf="!isLoading && !dashboardData?.rateRating"
              class="card-min-subtitle"
            >
              <span
                style="
                  font-size: 14px;
                  position: absolute;
                  top: 50px;
                  color: rgba(0, 0, 0, 0.4);
                  font-family: Nunito;
                  font-weight: 400;
                  line-height: 19px;
                  letter-spacing: 0em;
                "
              >
                Sin datos disponibles
              </span>
            </mat-card-title>
            <mat-card-title *ngIf="isLoading" class="card-min-process-bar">
              <mat-progress-bar color="warn" mode="indeterminate">
              </mat-progress-bar
            ></mat-card-title>
          </div>
          <div class="col-2">
            <button
              disabled
              mat-fab
              style="
                color: #244791;
                background-color: #d8e4fc;
                width: 64px;
                height: 64px;
              "
            >
              <mat-icon style="transform: scale(1.24); font-weight: bold;"
                >grade</mat-icon
              >
            </button>
          </div>
        </div>
      </mat-card>
    </div>

    <div class="col-8 card-responsive">
      <mat-card class="card-container" style="height: 390px;">
        <mat-card-title
          style="
            font-size: 20px;
            font-family: 'Nunito', sans-serif;
            font-weight: bold;
          "
        >
          Top 10 motivos de consulta
          <mat-icon
            style="position: absolute; margin-left: 5px; color: #244791;"
            matTooltip="Principales motivos de consulta realizados por los usuarios de su organización en el período de tiempo seleccionado."
            matTooltipClass="custom-tooltips"
            matTooltipPosition="after"
            >info_outline</mat-icon
          ></mat-card-title
        >
        <mat-divider></mat-divider>
        <mat-card-content>
          <mat-spinner
            *ngIf="isLoading"
            style="margin-top: 20%; margin-left: 45%;"
            diameter="60"
          ></mat-spinner>
          <p
            *ngIf="!isLoading && !isData"
            style="
              position: absolute;
              margin-top: 38%;
              margin-left: 30%;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            El gráfico aún no se encuentra disponible
          </p>
          <div id="canvasPies" class="pie-graphic"></div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
