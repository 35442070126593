import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  /**
   * Assign params with key value pare
   * @param params: any
   */
  /*   private getHttpParams(params) {
    let httpParams = new HttpParams();
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const element = params[key];
        httpParams = httpParams.set(key, element);
      }
    }
    return httpParams;
  } */

  getDashboardData(params) {
    return this.http.get(`/members/dashboard-consultation`, { params });
  }

  getMembersDashboardData(params) {
    return this.http.get(`/members/dashboard-member`, { params });
  }
}
