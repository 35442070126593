<div class="reset-container">
  <app-header></app-header>
  <div class="container">
    <h4 class="heading">{{ "Set New Password" | translate }}</h4>

    <p *ngIf="showError; else notError" style="font-weight: bold; color: red;">
      {{ errorMsg }}
    </p>

    <ng-template #notError>
      <p class="requirements-list-heading">
        {{ "It must meet the following characteristics" | translate }}:
      </p>

      <ul class="requirements-list">
        <li
          class="list-icon default"
          [ngClass]="{
            check:
              this.changepasswordForm.dirty &&
              this.containsAnyLetters(
                this.changepasswordForm.get('password').value
              ),
            error:
              this.changepasswordForm.dirty &&
              !this.containsAnyLetters(
                this.changepasswordForm.get('password').value
              )
          }"
        >
          {{ "Use at least one letter" | translate }}.
        </li>
        <li
          class="list-icon default"
          [ngClass]="{
            check:
              this.changepasswordForm.dirty &&
              this.containsNumbers(
                this.changepasswordForm.get('password').value
              ),
            error:
              this.changepasswordForm.dirty &&
              !this.containsNumbers(
                this.changepasswordForm.get('password').value
              )
          }"
        >
          {{ "Use at least one number" | translate }}.
        </li>
        <li
          class="list-icon default"
          [ngClass]="{
            check:
              this.changepasswordForm.dirty &&
              this.changepasswordForm.get('password').value.length >= 8,
            error:
              this.changepasswordForm.dirty &&
              this.changepasswordForm.get('password').value.length < 8
          }"
        >
          {{
            "Have a length greater than or equal to 8 characters" | translate
          }}.
        </li>
      </ul>
    </ng-template>

    <!-- <p class="subheading">Please enter your email below</p> -->
    <mat-card class="reset-card" *ngIf="!hideForm">
      <form
        class="example-form"
        [formGroup]="changepasswordForm"
        (ngSubmit)="changePasswordSubmit()"
      >
        <p class="enter-new-password">
          {{ "Enter a new password" | translate }}:
        </p>
        <mat-form-field
          class="example-full-width my-form-field"
          style="width: 80%;"
        >
          <input
            [type]="passwordHide ? 'password' : 'text'"
            matInput
            placeholder="{{ 'New Password' | translate }}"
            formControlName="password"
            required
          />
          <mat-icon matSuffix (click)="passwordHide = !passwordHide">{{
            passwordHide ? "visibility_off" : "visibility"
          }}</mat-icon>
        </mat-form-field>
        <br /><br />
        <mat-form-field
          class="example-full-width my-form-field"
          style="width: 80%;"
        >
          <input
            [type]="confirmPasswordHide ? 'password' : 'text'"
            matInput
            placeholder="{{ 'Confirm Password' | translate }}"
            formControlName="confirmPassword"
            required
          />
          <mat-icon
            matSuffix
            (click)="confirmPasswordHide = !confirmPasswordHide"
            >{{
              confirmPasswordHide ? "visibility_off" : "visibility"
            }}</mat-icon
          >
        </mat-form-field>

        <p
          class="error-text"
          *ngIf="
            this.pressedSubmit &&
            (!this.containsAnyLetters(
              this.changepasswordForm.get('password').value
            ) ||
              !this.containsNumbers(
                this.changepasswordForm.get('password').value
              ) ||
              this.changepasswordForm.get('password').value.length < 8)
          "
        >
          {{ "Your password must meet the above characteristics" | translate }}.
        </p>

        <p
          class="error-text"
          *ngIf="
            this.pressedSubmit &&
            this.changepasswordForm.get('password').value !==
              this.changepasswordForm.get('confirmPassword').value
          "
        >
          {{ "Your password must match" | translate }}
        </p>

        <div class="reset-button-row">
          <button [disabled]="loading" mat-raised-button>
            {{ "Guardar" | translate }}
          </button>
        </div>
      </form>
    </mat-card>
  </div>
</div>
