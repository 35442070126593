import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material.module";
import { HomeModule } from "./home/home.module";
import { LoginComponent } from "./login/login.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { CoreModule } from "./core/core.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "./http.interceptor";
import { SharedModule } from "./shared/shared.module";
import { HeaderComponent } from "./header/header.component";
import { TranslateModule } from "@ngx-translate/core";
import { LoaderInterceptorService } from "./loader-interceptor.service";
import { LoaderComponent } from "./loader/loader.component";
import { LottieModule } from "ngx-lottie";
import player from "lottie-web";
import { CustomMatPaginatorIntl } from "./custom-mat-paginator-int";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { DashboardModule } from "./dashboard/dashboard.module";
import { NgChartsModule } from "ng2-charts";

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PageNotFoundComponent,
    LoaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HomeModule,
    DashboardModule,
    NgChartsModule,
    TranslateModule.forRoot(),
    CoreModule,
    SharedModule,
    BrowserAnimationsModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true,
    },
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
