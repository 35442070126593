import { Component, OnInit } from "@angular/core";
import { LoaderService } from "../loader.service";
import { AnimationOptions } from "ngx-lottie";
import { AnimationItem } from "lottie-web";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent implements OnInit {
  loading: boolean;
  options: AnimationOptions = {
    path: "/assets/loader.json",
  };

  constructor(private loaderService: LoaderService) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }
  ngOnInit() {}

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
}
