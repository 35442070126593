import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CredentialsService } from "src/app/core/credentials.service";
import { TooltipPosition } from "@angular/material/tooltip";
import { FormControl, FormGroup } from "@angular/forms";
import { AuthenticationService } from "src/app/core/authentication.service";
import { I18nService } from "src/app/core/i18n.service";
import { SharedService } from "../shared.service";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-home-header",
  templateUrl: "./home-header.component.html",
  styleUrls: ["./home-header.component.scss"],
})
export class HomeHeaderComponent implements OnInit {
  userName: string;
  attachmentForm: FormGroup;
  providers: any = [];
  providersFiltered: any = [];
  providerSearchText="";
  showProvidersMenu = false;
  showOrNotProvidersMenu = false;
  tabActive = 0;
  providerName = "";
  links = [
    {
      name: "Dashboard",
      iconName: "dashboard",
      link: "/dashboard",
    },
    {
      name: "Miembros",
      iconName: "notes",
      link: "/members",
    },
  ];
  activeLink = this.links[0];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private credService: CredentialsService,
    private authService: AuthenticationService,
    private sharedService: SharedService,
    private i18nService: I18nService
  ) {}

  ngOnInit(): void {
    if (this.router.url == "/members") {
      this.tabActive = 1;
    } else {
      this.tabActive = 0;
    }
    this.providerName =
      this.credService.credentials?.userDetails?.provider?.name || "";

    this.userName =
      this.credService.credentials.userDetails.firstName +
      " " +
      this.credService.credentials.userDetails.lastName;

    this.providers = this.credService.credentials.userDetails.subsidiary;
    this.providersFiltered = this.providers.slice(0,4)
    this.showOrNotProvidersMenu = this.credService.credentials.userDetails.super;
  }

  gotoLandingPage() {
    if (this.credService.isAuthenticated()) {
      this.router.navigate(["/dashboard"]);
    } else {
      this.router.navigate(["/login"]);
    }
  }

  testing(event) {
    if (event.index == 0) {
      this.navigateDashboard();
    }

    if (event.index == 1) {
      this.navigateMembers();
    }
  }

  changePassword() {
    this.router.navigate(["/change-password"]);
  }

  navigateMembers() {
    this.router.navigate(["/members"]);
  }

  navigateDashboard() {
    this.router.navigate(["/dashboard"]);
  }

  logOut() {
    this.authService.logout().subscribe(
      (res: any) => {
        this.router.navigate(["/login"]);
      },
      (err: HttpErrorResponse) => {
        this.handleError(err);
      }
    );
  }

  changeLanguage(lang: string) {
    this.i18nService.language = lang;
    window.location.reload();
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }

  selectedProvider(provider) {
    let newCredentials = this.credService.credentials;
    newCredentials.userDetails.provider = provider;
    this.credService.setCredentials(newCredentials);
    this.router.navigateByUrl('/',{skipLocationChange:true}).then(()=>{
      if (this.tabActive == 1) {
        this.router.navigate(["/members"]);
      } else {
        this.router.navigate(["/dashboard"]);
      }
    })
  }

  providerSearchTyped(event){
    console.log(event.target.value)
    this.providerSearchText = event.target.value;

    if (this.providerSearchText.length == 0) {
      this.providersFiltered = this.providers.slice(0,4)
    } else {
      this.providersFiltered = this.providers.filter(provider => provider.businessName.toLowerCase().includes(this.providerSearchText) || provider.identificationNumber.includes(this.providerSearchText)).slice(0,4);
    }
  }

  showOrHideProviders() {
    if (this.showOrNotProvidersMenu) {
      this.showProvidersMenu = !this.showProvidersMenu;
    }
  }

}
