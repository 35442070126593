import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { DashboardComponent } from "../dashboard/dashboard.component";

const dashboardRoutes: Routes = [
  {
    path: "",
    data: { title: "home" },
    component: HomeComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./organization/organization.module").then(
            (o) => o.OrganizationModule
          ),
      },
      {
        path: "change-password",
        component: ChangePasswordComponent,
        data: { title: "Change Password" },
      },
    ],
  },
  { path: "**", component: DashboardComponent },
];

@NgModule({
  imports: [RouterModule.forChild(dashboardRoutes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
