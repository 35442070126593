import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ɵCurrencyIndex,
} from "@angular/core";
import { Chart, registerables } from "chart.js";
import moment from "moment";
import { SharedService } from "../shared/shared.service";
import { DashboardService } from "./dashboard.service";
import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { CredentialsService } from "../core/credentials.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  chart: any = [];
  chartPie: any = [];

  isData = false;
  isLoading = true;

  members: any;
  dashboardData: any;

  totalConsults = 0;
  totalUsers = 0;
  primaries = 0;
  successive = 0;
  totalMembers = 0;
  totalRegistered = 0;

  dateRanges = [
    {
      label: "Últimos 7 días",
      value: "7",
      time: "days",
    },
    {
      label: " Últimos 30 días",
      value: "30",
      time: "days",
    },
    {
      label: "Últimos 3 meses",
      value: "3",
      time: "month",
    },
    {
      label: "Últimos 6 meses",
      value: "6",
      time: "month",
    },
    {
      label: "Últimos 12 meses",
      value: "12",
      time: "month",
    },
    {
      label: "Desde siempre",
      value: "",
      time: "",
    },
  ];
  constructor(
    private readonly credService: CredentialsService,
    private readonly sharedService: SharedService,
    private readonly dashboardService: DashboardService
  ) {
    Chart.register(...registerables);
  }

  rangeSelected = {
    label: " Últimos 30 días",
    value: "30",
    time: "days",
  };

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.isLoading = true;

    const params = this.setParams(this.rangeSelected).set(
      "provider",
      this.credService.credentials.userDetails.provider.id ||
        this.credService.credentials.userDetails.provider
    );

    this.dashboardService.getMembersDashboardData(params).subscribe(
      (res: any) => {
        console.log("DATA Members", res["data"]);
        this.members = res["data"];
        this.totalRegistered = this.sharedService.formatNumber(
          this.members.totalRegisteredPeriod
        );
        this.totalMembers = this.sharedService.formatNumber(
          this.members.totalMembers
        );

        this.dashboardService.getDashboardData(params).subscribe(
          (res: any) => {
            console.log("DATA Dashboard", res["data"]);
            this.dashboardData = res["data"];
            this.totalConsults = this.sharedService.formatNumber(
              this.dashboardData.countConsultation +
                this.dashboardData.countFollowUp
            );
            this.primaries = this.sharedService.formatNumber(
              this.dashboardData.countConsultation
            );
            this.successive = this.sharedService.formatNumber(
              this.dashboardData.countFollowUp
            );
            if (
              this.members.dates.length <= 0 &&
              this.dashboardData.dates.length <= 0
            ) {
              this.isLoading = false;
            } else {
              if (this.members.dates.length > 0) {
                this.members.dates = this.setStartAndEnd(
                  this.members.dates,
                  this.rangeSelected
                );
              }
              if (this.dashboardData.dates.length > 0) {
                this.dashboardData.dates = this.setStartAndEnd(
                  this.dashboardData.dates,
                  this.rangeSelected
                );
              }
              if (this.rangeSelected.value == "") {
                this.members.trend = null;
                this.dashboardData.trendRating = null;
                this.dashboardData.trendConsultation = null;
                this.dashboardData.trendServiceLevel = null;
              }
              this.setData();
            }
          },
          (err: HttpErrorResponse) => {
            this.isLoading = false;
            this.isData = false;
            console.log(err);
          }
        );
      },
      (err: HttpErrorResponse) => {
        console.log(err);
        this.isLoading = false;
        this.isData = false;
      }
    );
  }

  setParams({ value, time }): HttpParams {
    switch (value) {
      case "7": {
        return new HttpParams()
          .set(
            "startDate",
            moment
              .utc()
              .subtract(value, time)
              .startOf("day")
              .format("YYYY-MM-DD")
          )
          .set("endDate", moment.utc().endOf("day").format("YYYY-MM-DD"))
          .set("timeScale", "1");
      }

      case "30": {
        return new HttpParams()
          .set(
            "startDate",
            moment
              .utc()
              .subtract(value, time)
              .startOf("day")
              .format("YYYY-MM-DD")
          )
          .set("endDate", moment.utc().endOf("day").format("YYYY-MM-DD"))
          .set("timeScale", "1");
      }

      case "3": {
        return new HttpParams()
          .set(
            "startDate",
            moment
              .utc()
              .subtract(value, time)
              .startOf("day")
              .format("YYYY-MM-DD")
          )
          .set("endDate", moment.utc().endOf("day").format("YYYY-MM-DD"))
          .set("timeScale", "1");
      }

      case "6": {
        return new HttpParams()
          .set(
            "startDate",
            moment
              .utc()
              .subtract(value, time)
              .startOf("day")
              .format("YYYY-MM-DD")
          )
          .set("endDate", moment.utc().endOf("day").format("YYYY-MM-DD"))
          .set("timeScale", "2");
      }

      case "12": {
        return new HttpParams()
          .set(
            "startDate",
            moment
              .utc()
              .subtract(value, time)
              .startOf("day")
              .format("YYYY-MM-DD")
          )
          .set("endDate", moment.utc().endOf("day").format("YYYY-MM-DD"))
          .set("timeScale", "2");
      }
      case "": {
        return new HttpParams().set("timeScale", "2");
      }

      default: {
        return new HttpParams()
          .set(
            "startDate",
            moment.utc().subtract(30, "days").startOf("day").toISOString()
          )
          .set("endDate", moment.utc().endOf("day").toISOString())
          .set("timeScale", "1");
      }
    }
  }

  capitalize(str) {
    const lower = str.toLowerCase();
    return str.charAt(0).toUpperCase() + lower.slice(1);
  }

  isNewMonthLabel(label: any[], date, indexLabel) {
    let isNewMonth = false;
    label.forEach((label, index) => {
      if (indexLabel == 0) {
        isNewMonth = true;
      }
      if (index < indexLabel) {
        const dateOne = moment.utc(new Date(label).toISOString()).get("month");
        const dateTwo = moment.utc(date).get("month");
        if (dateOne == dateTwo) {
          isNewMonth = false;
        } else {
          isNewMonth = true;
        }
      }
    });
    return isNewMonth;
  }

  setStartAndEnd(dates: any[], { value, time }) {
    const valueTime = time == "days" ? parseInt(value) - 1 : value;

    const startDate = moment.utc().subtract(valueTime, time);
    const endDate = moment.utc();

    if (
      !dates.find((date) => {
        const currentDate = new Date(date.date).toISOString();
        return moment
          .utc(currentDate)
          .startOf(value == "6" || value == "12" ? time : "day")
          .isSame(
            startDate.startOf(value == "6" || value == "12" ? time : "day")
          );
      })
    ) {
      dates.push({ date: startDate.toISOString(), quantity: 0 });
    }
    if (
      !dates.find((date) => {
        const currentDate = new Date(date.date).toISOString();
        return moment
          .utc(currentDate)
          .endOf(value == "6" || value == "12" ? time : "day")
          .isSame(endDate.endOf(value == "6" || value == "12" ? time : "day"));
      })
    ) {
      dates.push({ date: endDate.toISOString(), quantity: 0 });
    }

    dates.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );

    return dates;
  }

  validateSplit(dates, { value, time }) {
    let status;
    if (value == "30" && dates.length > 12) {
      status = true;
    } else if (value == "3" && dates.length < 14) {
      status = false;
    } else if (value == "3" && dates.length > 14) {
      status = true;
    } else {
      status = false;
    }
    return status;
  }

  splitDates(dates, { value, time }, type) {
    moment.locale("es");

    const newDatesArray = [];

    const startDate = moment.utc().subtract(parseInt(value) - 1, time);

    let days = 0;
    let jump = 0;

    if (value == "30" && dates.length >= 12) {
      days = 6;
      jump = 6;
    }
    if (value == "3" && dates.length > 30) {
      days = 14;
      jump = 14;
    } else if (value == "3" && dates.length < 30) {
      days = 7;
      jump = 7;
    }
    let currentDay = 0;

    while (days <= dates.length) {
      let count = 0;
      let starSplit: moment.Moment;
      let endSplit: moment.Moment;
      dates.forEach((date) => {
        const newDate = new Date(date.date).toISOString();
        starSplit = moment.utc(startDate).add(currentDay, "d").startOf("day");
        endSplit = moment
          .utc(startDate)
          .add(days - 1, "d")
          .endOf("day");
        if (
          moment.utc(newDate).isBetween(starSplit, endSplit) ||
          moment.utc(newDate).isSame(starSplit) ||
          moment.utc(newDate).isSame(endSplit)
        ) {
          count = count + date.quantity;
        }
      });

      newDatesArray.push({
        date:
          (newDatesArray.length == 0
            ? this.capitalize(starSplit.format("MMM DD"))
            : starSplit.format("DD")) +
          "-" +
          (endSplit.get("month") != starSplit.get("month")
            ? this.capitalize(endSplit.format("MMM DD"))
            : endSplit.format("DD")),
        quantity: count,
      });

      currentDay = days;

      days = days + jump;
    }
    const daysLeft = dates.filter((dates) => {
      const startDateLeft = moment
        .utc(startDate)
        .add(days - jump, "d")
        .startOf("d");
      const endDate = moment.utc().endOf("d");
      const date = new Date(dates.date).toISOString();

      return (
        moment.utc(date).isBetween(startDateLeft, endDate) ||
        moment.utc(date).isSame(startDateLeft) ||
        moment.utc(date).isSame(endDate)
      );
    });
    if (daysLeft.length > 0) {
      daysLeft.forEach((date) => {
        const isNewMonth = this.isNewMonthLabel(
          dates.map((date) => date.date),
          date.date,
          dates.findIndex((dates) => dates.date == date.date)
        );
        newDatesArray.push({
          date: isNewMonth
            ? this.capitalize(moment.utc(date.date).format("MMM DD"))
            : moment.utc(date.date).format("DD"),
          quantity: date.quantity,
        });
      });
    }

    if (type == "dates") {
      return newDatesArray.map((newArray) => newArray.date);
    }
    if (type == "count") {
      return newDatesArray.map((newArray) => newArray.quantity);
    }
  }

  getGraphicLineLabels(label, index) {
    const indexLabel = label.find((label, indexL) => indexL == index);
    const date = new Date(indexLabel).toISOString();
    const isNewMonth = this.isNewMonthLabel(label, date, index);
    if (
      this.rangeSelected.value == "7" ||
      this.rangeSelected.value == "30" ||
      this.rangeSelected.value == "3"
    ) {
      if (
        moment
          .utc(date)
          .startOf("day")
          .isSame(moment.utc(date).startOf("month")) ||
        isNewMonth
      ) {
        return this.capitalize(moment.utc(date).format("MMM DD"));
      } else {
        return moment.utc(date).format("DD");
      }
    } else {
      return this.capitalize(moment.utc(date).format("MMM"));
    }
  }
  getTooltipsLabel(label) {
    const date = new Date(label).toISOString();
    if (
      this.rangeSelected.value == "7" ||
      this.rangeSelected.value == "30" ||
      this.rangeSelected.value == "3"
    ) {
      return moment.utc(date).format("DD MMMM");
    } else {
      return moment.utc(date).format("MMMM");
    }
  }
  setData() {
    moment.locale("es");

    const labels = this.validateSplit(
      this.dashboardData.dates,
      this.rangeSelected
    )
      ? this.splitDates(this.dashboardData.dates, this.rangeSelected, "dates")
      : this.dashboardData.dates.map((dates) => dates.date);
    const data = this.validateSplit(
      this.dashboardData.dates,
      this.rangeSelected
    )
      ? this.splitDates(this.dashboardData.dates, this.rangeSelected, "count")
      : this.dashboardData.dates.map((dates) => dates.quantity);
    const labelsUsers = this.validateSplit(
      this.members.dates,
      this.rangeSelected
    )
      ? this.splitDates(this.members.dates, this.rangeSelected, "dates")
      : this.members.dates.map((dates) => dates.date);
    const dataUsers = this.validateSplit(this.members.dates, this.rangeSelected)
      ? this.splitDates(this.members.dates, this.rangeSelected, "count")
      : this.members.dates.map((dates) => dates.quantity);
    const labelsPie = this.dashboardData.topChiefComplaint.map(
      (top) => top.name
    );
    const dataPie = this.dashboardData.topChiefComplaint.map(
      (top) => top.count
    );

    const canvas = document.createElement("canvas");
    canvas.height = 115;
    canvas.id = "canvasUser";

    const canvas2 = document.createElement("canvas");
    canvas2.height = 115;
    canvas2.id = "canvasConsult";

    const canvasPie = document.createElement("canvas");
    canvasPie.id = "canvasPie";

    let ctx = canvas.getContext("2d");
    let ctx2 = canvas2.getContext("2d");
    let ctxPie = canvasPie.getContext("2d");
    var gradient = ctx.createLinearGradient(100, 0, 100, 250);
    var gradient2 = ctx2.createLinearGradient(100, 0, 100, 250);

    gradient.addColorStop(0, "rgba(99, 145, 244, 1)");
    gradient.addColorStop(1, "rgba(99, 145, 244, 0.1)");
    gradient2.addColorStop(0, "rgba(42, 83, 169, 1)");
    gradient2.addColorStop(1, "rgba(42, 83, 169, 0.1)");

    this.chart = new Chart(ctx, {
      type: "line",
      data: {
        labels: this.validateSplit(this.members.dates, this.rangeSelected)
          ? labelsUsers
          : labelsUsers.map((date) => this.getTooltipsLabel(date)),
        datasets: [
          {
            label: null,
            data: dataUsers,
            borderWidth: 1,
            fill: true,
            borderColor: "rgba(99, 145, 244, 1)",
            backgroundColor: gradient,
            borderCapStyle: "round",
            tension: 0.5,
            pointBorderWidth: 3,
            pointBackgroundColor: "#51A7FD",
            pointHoverRadius: 10,
            pointHoverBorderWidth: 3,
            pointHoverBorderColor: "#FFFFFF",
            pointHoverBackgroundColor: "#51A7FD",
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              padding: 0,
              font: {
                family: "'Nunito', sans-serif",
                size: 14,
              },
              maxTicksLimit: 7,
              callback: (value, index, ticks) => {
                return this.validateSplit(
                  this.members.dates,
                  this.rangeSelected
                )
                  ? labelsUsers.find((label, indexL) => indexL == index)
                  : this.getGraphicLineLabels(labelsUsers, index);
              },
            },
          },
          y: {
            display: false,
            suggestedMax: Math.max(...dataUsers) + Math.max(...dataUsers) / 10,
            suggestedMin: Math.max(...dataUsers) / 10,
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            backgroundColor: "rgba(99, 145, 244, 1)",
            displayColors: false,
            bodyColor: "#FFFFFF",
            padding: 10,
            titleAlign: "center",
            bodyAlign: "center",
            footerAlign: "center",
            titleFont: {
              family: "'Nunito', sans-serif",
              size: 14,
            },
          },
        },
      },
    });

    this.chart = new Chart(ctx2, {
      type: "line",
      data: {
        labels: this.validateSplit(this.dashboardData.dates, this.rangeSelected)
          ? labels
          : labels.map((date) => this.getTooltipsLabel(date)),
        datasets: [
          {
            label: null,
            data: data,
            borderWidth: 1,
            fill: true,
            borderColor: "rgba(99, 145, 244,1)",
            backgroundColor: gradient2,
            borderCapStyle: "round",
            tension: 0.5,
            pointBorderWidth: 3,
            pointBackgroundColor: "#2751A5",
            pointHoverRadius: 10,
            pointHoverBorderWidth: 3,
            pointHoverBorderColor: "#FFFFFF",
            pointHoverBackgroundColor: "#2751A5",
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              padding: 0,
              font: {
                family: "'Nunito', sans-serif",
                size: 14,
              },
              maxTicksLimit: 7,
              callback: (value, index, ticks) => {
                return this.validateSplit(
                  this.dashboardData.dates,
                  this.rangeSelected
                )
                  ? labels.find((label, indexL) => indexL == index)
                  : this.getGraphicLineLabels(labels, index);
              },
            },
          },
          y: {
            suggestedMax: Math.max(...data) + Math.max(...data) / 10,
            suggestedMin: Math.max(...data) / 10,
            display: false,
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            backgroundColor: "rgba(42, 83, 169, 1)",
            displayColors: false,
            bodyColor: "#FFFFFF",
            padding: 10,
            titleAlign: "center",
            bodyAlign: "center",
            footerAlign: "center",
            titleFont: {
              family: "'Nunito', sans-serif",
              size: 14,
            },
          },
        },
      },
    });

    this.chart = new Chart(ctxPie, {
      type: "pie",
      data: {
        labels: labelsPie,
        datasets: [
          {
            label: null,
            data: dataPie,
            borderColor: "rgba(36, 71, 145, 0)",
            backgroundColor: [
              "rgba(2, 12, 25, 1)",
              "rgba(6, 36, 76, 1)",
              "rgba(10, 60, 127, 1)",
              "rgba(14, 84, 178, 1)",
              "rgba(18, 108, 229, 1)",
              "rgba(90, 161, 255, 1)",
              "rgba(33, 56, 100, 1)",
              "rgba(57, 78, 117, 1)",
              "rgba(107, 122, 152, 1)",
              "rgba(206, 211, 221, 1)",
            ],
            hoverBackgroundColor: [
              "rgba(2, 12, 25, 1)",
              "rgba(6, 36, 76, 1)",
              "rgba(10, 60, 127, 1)",
              "rgba(14, 84, 178, 1)",
              "rgba(18, 108, 229, 1)",
              "rgba(90, 161, 255, 1)",
              "rgba(33, 56, 100, 1)",
              "rgba(57, 78, 117, 1)",
              "rgba(107, 122, 152, 1)",
              "rgba(206, 211, 221, 1)",
            ],
            hoverBorderColor: "rgba(36, 71, 145, 0)",
            hoverOffset: 4,
          },
        ],
      },
      options: {
        radius: 150,
        responsive: true,
        plugins: {
          tooltip: {
            backgroundColor: "#FFFFFF",
            displayColors: false,
            bodyColor: "rgba(0, 0, 0, 0.7)",
            padding: 10,
            cornerRadius: 0,
            titleAlign: "center",
            bodyAlign: "center",
            footerAlign: "center",
            bodyFont: {
              family: "'Nunito', sans-serif",
              size: 14,
            },
          },
          legend: {
            position: "right",
            fullSize: false,
            labels: {
              padding: 15,
              textAlign: "left",
              font: {
                family: "'Nunito', sans-serif",
                size: 12,
              },
            },
          },
        },
      },
    });
    document.querySelector("#canvas2").appendChild(canvas);
    document.querySelector("#canvas3").appendChild(canvas2);
    document.querySelector("#canvasPies").appendChild(canvasPie);

    this.isLoading = false;
    this.isData = true;
  }

  isInteger(number) {
    if (number - Math.floor(number) == 0) {
      return number;
    } else {
      return number.toFixed(1);
    }
  }

  changeRange(range: string) {
    this.isData = false;
    this.isLoading = true;
    this.rangeSelected = this.dateRanges.find(
      (dateRange) => dateRange.value == range
    );

    const canvas = document.getElementById("canvasUser");
    const canvas2 = document.getElementById("canvasConsult");
    const canvasPie = document.getElementById("canvasPie");
    if (canvas && canvas2 && canvasPie) {
      canvas.remove();
      canvas2.remove();
      canvasPie.remove();
    }
    this.getData();
  }
}
